<template>
  <div>
    <b-tabs
      lazy
      pills
      active-nav-item-class="bg-light-primary border-0 shadow-none cursor-pointer"
    >
      <b-tab :title="lang('t_dialer')" active>
        <dialer :getCampaignDetails="getCampaignDetails"></dialer>
      </b-tab>
      <b-tab :title="lang('t_campaign')">
        <campaign></campaign>
      </b-tab>
    </b-tabs>

    <!-- <b-button
        v-if="selected_campaign != null"
        class="float-right ml-auto mr-1"
        variant="primary"
        @click="settings_modal = true"
      >
        <feather-icon icon="SettingsIcon" />
      </b-button> -->

    <b-modal
      static
      v-if="selected_campaign != null"
      size="lg"
      scrollable
      no-close-on-esc
      no-close-on-backdrop
      v-model="settings_modal"
      :title="lang('t_settings')"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_close')"
      @ok="save_campaign_settings"
    >
      <b-tabs>
        <b-tab active style="height: 620px">
          <template #title>
            <feather-icon icon="SlidersIcon" />
            <span>{{ lang("t_callStrategy") }}</span>
          </template>

          <b-card-text>
            <b-row v-if="selected_campaign.mode == 'dialer'">
              <b-col>
                <!-- <b-form-group
                  :label="lang('t_priority')"
                  label-for="priority"
                  description="Kampanyalar arası çağrı önceliği"
                >
                  <b-form-input
                    id="priority"
                    :placeholder="lang('t_priority')"
                    type="number"
                  />
                </b-form-group> -->
                <b-form-group
                  :label="lang('t_maxAbandonRate')"
                  label-for="max_abandon_rate"
                  :description="
                    lang('t_maximumRateOfCallsThatAreAbandonedFromTheQueue')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.abandon_rate"
                    id="max_abandon_rate"
                    :placeholder="lang('t_maxAbandonRate')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_callsPerAgent')"
                  label-for="call_per_agent"
                  :description="lang('t_callsPerAvailableAgents')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.call_per_agent"
                    id="call_per_agent"
                    :placeholder="lang('t_callsPerAgent')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_maxCall')"
                  label-for="max_calls"
                  :description="
                    lang('t_maximumAmountOfCallsThisCampaignCanStart')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_call"
                    id="max_calls"
                    :placeholder="lang('t_maxCall')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_statisticsPeriod')"
                  label-for="s_period"
                  :description="
                    lang('t_refreshRateOfThisCampaignsStatisticsGraphs')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.statistics_period"
                    id="s_period"
                    :placeholder="lang('t_statisticsPeriod')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_callingMethod')"
                  label-for="line_group"
                  :description="lang('t_whichLineGroupToMakeCallsFrom')"
                >
                  <!-- <b-form-input
                    id="line_group"
                    :placeholder="lang('t_callingMethod')"
                    type="number"
                  /> -->
                  <v-select
                    v-model="selected_campaign.settings.line_group"
                    :options="lg"
                    :placeholder="lang('t_campaign')"
                    :reduce="(option) => option"
                    label="display_name"
                  >
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_noAnswerTimeout')"
                  label-for="no_answer_time_out"
                  :description="lang('t_maximumRingingTime')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.timeout_second"
                    id="no_answer_time_out"
                    :placeholder="lang('t_noAnswerTimeout')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_maxCycles')"
                  label-for="max_cycles"
                  :description="
                    lang(
                      't_amountOfRoundsToCallPeopleWhoHaventBeenContactedYet'
                    )
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_recyle"
                    id="max_cycles"
                    :placeholder="lang('t_maxCycles')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_recallDelayMins')"
                  label-for="recall_delay_mins"
                  :description="
                    lang('t_minimumAmountOfMinutesToRecallAPhoneNumber')
                  "
                >
                  <b-form-input
                    v-model="selected_campaign.settings.next_call_time"
                    id="no_answer_time_out"
                    :placeholder="lang('t_recallDelayMins')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_dailyMaxPhoneTouch')"
                  label-for="max_phone_attempts"
                  :description="lang('t_maximumCallAmountPerPhoneNumberPerDay')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_phone_touch"
                    id="max_phone_attempts"
                    :placeholder="lang('t_dailyMaxPhoneTouch')"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_dailyMaxContactTouch')"
                  label-for="max_contact_attempts"
                  :description="lang('t_maximumCallAmountPerContactPerDay')"
                >
                  <b-form-input
                    v-model="selected_campaign.settings.max_contact_touch"
                    id="max_contact_attempts"
                    :placeholder="lang('t_dailyMaxContactTouch')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_totalMaxPhoneTouch')"
                  label-for="max_phone_attempts"
                  :description="lang('t_maximumCallAmountPerPhoneNumber')"
                >
                  <b-form-input
                    v-model="
                      selected_campaign.settings.max_phone_total_attempts
                    "
                    id="max_phone_attempts_total"
                    type="number"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_totalMaxContactTouch')"
                  label-for="max_contact_attempts_total"
                  :description="lang('t_maximumCallAmountPerContact')"
                >
                  <b-form-input
                    v-model="
                      selected_campaign.settings.max_contact_total_attempts
                    "
                    id="max_contact_attempts"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
        <b-tab style="height: 620px">
          <template #title>
            <feather-icon icon="ShuffleIcon" />
            <span>{{ lang("t_sortCriteria") }}</span>
          </template>

          <b-card-text>
            <div class="mt-1">
              <b-row>
                <!-- people group 1 -->
                <b-col md="6" class="text-center">
                  <h6 class="text-primary font-weight-bold mb-2">
                    {{ lang("t_columns") }}
                  </h6>

                  <!-- draggable -->
                  <draggable
                    style="min-height: 500px"
                    :list="list1"
                    tag="ul"
                    group="people"
                    class="list-group list-group-flush cursor-move"
                  >
                    <b-list-group-item
                      v-for="(listItem, index) in list1"
                      :key="index"
                      tag="li"
                      class="border-primary rounded mt-50"
                    >
                      <div class="d-flex">
                        <div class="pl-25">
                          <b-card-text class="mb-0 font-weight-bold">
                            {{ listItem.display_name }}
                          </b-card-text>
                        </div>
                      </div>
                    </b-list-group-item>
                  </draggable>
                </b-col>

                <!-- people group 2 -->
                <b-col md="6" class="mt-sm-2 mt-md-0 text-center">
                  <h6 class="text-primary font-weight-bold mb-2">
                    {{ lang("t_sort") }}
                  </h6>

                  <!-- draggable -->
                  <draggable
                    style="min-height: 500px"
                    :list="list2"
                    tag="ul"
                    group="people"
                    class="list-group list-group-flush cursor-move"
                  >
                    <b-list-group-item
                      v-for="(listItem, index) in list2"
                      :key="index"
                      tag="li"
                      class="border-primary rounded mt-50"
                    >
                      <div>
                        <div class="pl-50">
                          <b-card-text class="mb-0 font-weight-bold">
                            <b-row align-h="between">
                              <span> {{ listItem.display_name }} </span>
                              <b-form-checkbox
                                v-model="listItem.desc"
                                size="md"
                                class="custom-control-primary"
                                name="check-button"
                                switch
                              >
                                <span class="switch-icon-left">BK </span>
                                <span class="switch-icon-right"> KB </span>
                              </b-form-checkbox>
                            </b-row>
                          </b-card-text>
                        </div>
                      </div>
                    </b-list-group-item>
                  </draggable>
                </b-col>
              </b-row>
            </div>
          </b-card-text>
        </b-tab>
        <b-tab style="height: 620px">
          <template #title>
            <feather-icon icon="SettingsIcon" />
            <span>{{ lang("t_settings") }}</span>
          </template>

          <b-card-text>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_timeCondition')"
                  label-for="live-contact"
                >
                  <v-select
                    v-model="selected_campaign.settings.time_condition"
                    :options="tc"
                    :placeholder="lang('t_pleaseSelectTimeCondition')"
                    label="display_name"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_liveContact')"
                  label-for="live-contact"
                >
                  <v-select
                    v-model="selected_campaign.settings.live_contact.category"
                    :options="[
                      `${lang('t_transferIVR')}`,
                      `${lang('t_close')}`,
                    ]"
                    :placeholder="lang('contact')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="lang('t_noAvailableAgent')"
                  label-for="no-available-agent"
                >
                  <v-select
                    v-model="selected_campaign.settings.no_idle_agent.category"
                    :options="[
                      `${lang('t_transferIVR')}`,
                      `${lang('t_close')}`,
                    ]"
                    :placeholder="lang('t_noAvailableAgent')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  v-if="
                    selected_campaign.settings.live_contact.category ==
                    `${lang('t_transferIVR')}`
                  "
                  :label="lang('t_liveContacttIVR')"
                  label-for="live-contact-ivr"
                >
                  <v-select
                    @input="set_current_queue"
                    v-model="selected_campaign.settings.live_contact.target"
                    :options="ivr"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContacttIVR')"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  v-if="
                    selected_campaign.settings.no_idle_agent.category ==
                    `${lang('t_transferIVR')}`
                  "
                  :label="lang('t_noAvailableAgentIVR')"
                  label-for="no-available-agent-ivr"
                >
                  <v-select
                    @input="set_current_queue"
                    v-model="selected_campaign.settings.no_idle_agent.target"
                    :options="ivr"
                    :reduce="(val) => val.internal_name"
                    label="display_name"
                    :placeholder="lang('t_liveContacttIVR')"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="lang('t_dontConnectMachines')"
                  label-for="dont_connect_machines"
                  :description="lang('t_doNotConnectMachineAnsweredCalls')"
                >
                  <b-form-checkbox
                    v-model="selected_campaign.settings.machine_detection"
                    class="custom-control-primary"
                    name="dont_connect_machines"
                    switch
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {

  BContainer,
  BListGroupItem,
  BFormCheckbox,
  BTabs,
  BTab,
  BModal,
  BFormRadioGroup,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BButton,
} from "bootstrap-vue";
// import SmsSender from './SmsSender.vue'
import Campaign from "./Campaign/list/List.vue";
import Dialer from "./Dialer/Dialer.vue";
import draggable from "vuedraggable";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import vSelect from "vue-select";

export default {
  methods: {
    set_current_queue() {
      var queue = {
        internal_name: "",
        display_name: "",
      };
      console.log(
        "this.selected_campaign.settings.live_contact.target",
        this.selected_campaign.settings.live_contact.target
      );

      if (this.selected_campaign.settings.live_contact.target != "") {
        var _ivr = this.ivr.find(
          (e) =>
            e.internal_name ===
            this.selected_campaign.settings.live_contact.target
        );
        console.log("_ivr", _ivr);
        if (_ivr.ivr_object[0].name == "queue") {
          queue["internal_name"] = _ivr.ivr_object[0].target.internal_name;
          queue["display_name"] = _ivr.ivr_object[0].target.display_name;
        } else if (_ivr.ivr_object[0].name == "menu") {
          for (const item of _ivr.ivr_object[0].tasks) {
            if (item.name == "queue") {
              queue["internal_name"] = item.target.internal_name;
              queue["display_name"] = item.target.display_name;
              break;
            }
          }
        }
      }
      console.log("queue", queue);
      this.selected_campaign.settings.queue = queue.internal_name;
      this.selected_campaign.settings.queue_display_name = queue.display_name;

      this.selected_campaign.settings.trunk =
        this.selected_campaign.settings.line_group.internal_name;
      this.selected_campaign.settings.trunk_prefix =
        this.selected_campaign.settings.line_group.prefix;
      this.selected_campaign.settings.caller_id =
        this.selected_campaign.settings.line_group.caller_number;
    },
    getCampaignDetails(c_details) {
      console.log("getCampaignDetails", c_details);
      this.selected_campaign = c_details;
      this.getCustomerColumns();
      // this.tmp_potential_columns = [...this.columns];

      if (this.selected_campaign.settings != null) {
        this.list2 = this.selected_campaign.settings.sort_criteria;
        if (
          this.selected_campaign.settings.sort_criteria != null &&
          this.selected_campaign.settings.sort_criteria != []
        ) {
          for (const item of this.selected_campaign.settings.sort_criteria) {
            this.list1 = this.list1.filter(
              (e) => e.internal_name != item.internal_name
            );
          }
        }
      }
    },
    get_IvrManagementTargets: async function () {
      var response = (await this.$http_in.get(`spv/v1/IvrManagementTargets`))
        .data;

      this.tc = response.TimeConditions;
      this.ivr = response.Ivr;
    },
    get_LineGroup: async function () {
      var response = (await this.$http_in.get(`spv/v1/LineGroup`)).data;

      this.lg = response;
      console.log("asd", response);
    },
    get_line: async function () {
      var response = (
        await this.$http.get(`/Line`, {
          headers: {
            authorization: globalThis.token,
          },
        })
      ).data;

      this.lines = response;
    },
    save_campaign_settings: async function () {
      this.selected_campaign.settings.sort_criteria = this.list2;
      var response = (
        await this.$http_in.put(`spv/v1/CampaignSettings`, {
          data: this.selected_campaign.settings,
          id: this.selected_campaign._id,
        })
      ).data;

      console.log("this.selected_campaign", this.selected_campaign);
      await this.$http_in.get(
        `dlr/v1/RefreshCampaign/${this.selected_campaign.internal_name}`
      );

      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }
    },
    getCustomerColumns: async function () {
      var response = (
        await this.$http_in.get(
          `spv/v1/ProjectColumns/${this.selected_campaign.project}_customers`
        )
      ).data;
      console.log("response", response);
      let _data = [];
      for (const item of response[0].allkeys) {
        if (
          [
            "active",
            "_id",
            "cycles",
            "TCKN_masked",
            "notes",
            "phones",
            "products",
            "campaign",
            "sms",
            "extra_data",
            "daily_attempts",
            "update_date",
            "assigned_agent2",
          ].includes(item)
        ) {
          continue;
        }
        _data.push({
          internal_name: item,
          display_name:
            this.customer_column_labels[item] == undefined
              ? item
              : this.customer_column_labels[item],
          desc: false,
        });
      }
      // this.editedItem.column = _data[0];
      this.list1 = _data;
      this.columns = _data;
      //this.editedItem.column = _data[0];
      console.log("columns", _data);
    },
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      selected_campaign: null,
      selectedButton: "dialer",
      settings_modal: false,
      tc: [],
      ivr: [],
      lg: [],
      lines: [],
      columns: [],
      list1: [
        // {
        //   name: 'Adı Soyadı',
        // },
        // {
        //   name: 'Şehir',
        // },
      ],
      list2: [
        // {
        //   name: 'Toplam Temas',
        // },
        // {
        //   name: 'Bakiye',
        // },
      ],
      customer_column_labels: {
        status: globalThis._lang("t_customersStatus"),
        assigned_agent: globalThis._lang("t_assigned_agent"),
        attempts: globalThis._lang("t_attempts"),
        campaign: globalThis._lang("t_campaign"),
        finish_code: globalThis._lang("t_finish_code"),
        insert_date: globalThis._lang("t_insert_date"),
        action_date: globalThis._lang("t_action_date"),
        create_date: globalThis._lang("t_create_date"),
        ex_agent: globalThis._lang("t_ex_agent"),
        assign_date: globalThis._lang("t_assign_date"),
      },
    };
  },
  components: {
    BContainer,
    vSelect,
    Prism,
    draggable,
    BListGroupItem,
    BFormCheckbox,
    BFormInput,
    BTabs,
    BTab,
    BModal,
    Campaign,
    Dialer,
    selectedButton: "dialer",
    BFormRadioGroup,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BButton,
  },
  mounted: async function () {
    await this.get_IvrManagementTargets();
    // await this.get_line();
    await this.get_LineGroup();
  },
  beforeDestroy: function () {
    // localStorage.setItem('dialer_cache', JSON.stringify({
    //   selected_campaign: this.selected_campaign,
    // }));
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

